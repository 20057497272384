import dynamic from 'next/dynamic';

export const Select = dynamic(
  () => import('antd/lib/select'),
  { ssr: false },
);

export const SelectOption = dynamic(
  () => import('antd').then((c) => c.Select.Option),
  { ssr: false },
);
