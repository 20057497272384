import React, {
  FC, HTMLAttributes, useEffect, useState,
} from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { ListenIcon } from '~/atomic/atom/icon/ListenIcon';
import { TextBoldStyle } from '~/atomic/Typography';
import { loadAudioPlayer, openAudioPlayer } from '~/feature/audio/audioPlayer.data';
import { AudioPlayerBook, AudioPlayerChapter } from '~/feature/audio/audioPlayer.slice';
import { isAvailableListerAudioBookSelector } from '~/feature/book/book.selector';
import { GraphqlAudioBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

interface ListenAudioBookButtonProps extends HTMLAttributes<HTMLDivElement> {
  book: AudioPlayerBook & { audiobook: GraphqlAudioBook };
  chapters: AudioPlayerChapter[];
  iconCss?: FlattenSimpleInterpolation;
  isShowText?: boolean;
  text?: string;
}

export const ListenAudioBookButton: FC<ListenAudioBookButtonProps> = ({
  book,
  chapters,
  onClick,
  isShowText = false,
  iconCss,
  text = 'Слушать',
  ...props
}) => {
  const dispatch = useAppDispatch();
  const [isOpenPlayer, setIsOpenPlayer] = useState(false);
  const isAvailableListerAudioBook = useAppSelector(isAvailableListerAudioBookSelector(book));

  const listerAudioBookHandler = async (e) => {
    if (isAvailableListerAudioBook) {
      if (onClick) await onClick(e);

      if (isAvailableListerAudioBook) {
        setIsOpenPlayer(true);
      }
    }
  };

  useEffect(() => {
    if (isAvailableListerAudioBook) {
      if (chapters?.length && isOpenPlayer) {
        setIsOpenPlayer(false);
        dispatch(loadAudioPlayer({ book, chapters }));
        dispatch(openAudioPlayer());
      }
    }
  }, [chapters, isOpenPlayer]);

  if (!isAvailableListerAudioBook) {
    return null;
  }

  return (
    <SCListenBookButton
      onClick={listerAudioBookHandler}
      {...props}
    >
      <SCIcon css={iconCss} />
      {isShowText ? (
        <SCText>
          {text}
        </SCText>
      ) : null}
    </SCListenBookButton>
  );
};

const SCListenBookButton = styled.div`
  max-width: 83px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77A3BA;
  border-radius: 2px;
  cursor: pointer;
  flex-shrink: 0;
  
  ${lessThan('sm')} {
    background-color: var(--white-color);
    height: auto;
    max-width: 30px;
  }
  
  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const SCIcon = styled(ListenIcon)<{ css: FlattenSimpleInterpolation }>`
  width: 21px;
  height: 19px;
  fill: var(--white-color);

  ${lessThan('sm')} {
    fill: var(--black-color);
  }
  
  ${(props) => props.css}
`;

const SCText = styled.span`
  ${TextBoldStyle};
  color: var(--white-color);
`;
