import type { DropDownProps } from 'antd/lib/dropdown';
import React, {
  CSSProperties, FC, useState,
} from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum, MyBookStatusTextEnum } from '~/api/account/myBooksApiTypes';
import { Book } from '~/api/book/bookApiTypes';
import { Button, ButtonSize } from '~/atomic/atom/Button';
import { Dropdown } from '~/atomic/atom/Dropdown';
import { AddIcon } from '~/atomic/atom/icon/AddIcon';
import { BookmarkIcon } from '~/atomic/atom/icon/BookmarkIcon';
import { BooksIcon } from '~/atomic/atom/icon/BooksIcon';
import { ReadersIcon } from '~/atomic/atom/icon/ChapterInfo/ReadersIcon';
import { LoaderIcon } from '~/atomic/atom/icon/LoaderIcon';
import { BookReaderThemeEnum } from '~/atomic/page/book-reader/reader.slice';
import { changeBookStatus, deleteMyBook } from '~/atomic/page/mybooks/myBooks.data';
import { TextBoldStyle } from '~/atomic/Typography';
import { ReadBookStatusList } from '~/feature/book/ReadBookStatusList';
import { subscribeBookAndOpenModal } from '~/feature/book/SubscribeBookModal/subscribeBookModal.data';
import { subscribeBookModalActions } from '~/feature/book/SubscribeBookModal/subscribeBookModal.slice';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { useIsClientRender } from '~/lib/hooks/useIsClientRender';
import { useOutsideClick } from '~/lib/hooks/useOutsideClick';
import { useAppDispatch, useAppSelector } from '~/store';

interface MyBooksStatusProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  style?: CSSProperties;
  theme?: BookReaderThemeEnum;
  book: Partial<Book | GraphqlBook>;
  onAddBookInMyBooks?(status: MyBookStatusEnum): void;
  onClick?: () => void;
  isShowText?: boolean;
  placement?: DropDownProps['placement'];
}

export const MyBooksStatusButton: FC<MyBooksStatusProps> = ({
  style,
  theme,
  book,
  onAddBookInMyBooks,
  isShowText = true,
  placement = 'bottomCenter',
  onClick = () => {},
  ...props
}) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const [loading, setLoading] = useState(false);
  const [isVisibleList, setIsVisibleList] = useState(false);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isClient = useIsClientRender();
  const buttonSize: ButtonSize = isMobileSize && isClient ? 'normal' : 'large';

  const refRegister = useOutsideClick({
    callback: () => {
      setIsVisibleList(false);
    },
    isActive: isVisibleList,
  });

  const setStatus = async (status: MyBookStatusEnum) => {
    setLoading(true);
    await dispatch(changeBookStatus({
      bookId: book.id, status, currentStatus: book.myBook.readStatus,
    }));
    setLoading(false);
    setIsVisibleList(false);
    if (onAddBookInMyBooks) onAddBookInMyBooks(status);
  };

  const onDeleteStatusHandler = async () => {
    setLoading(true);
    await dispatch(deleteMyBook({ bookId: book.id }));
    setLoading(false);
    setIsVisibleList(false);
    if (onAddBookInMyBooks) onAddBookInMyBooks(MyBookStatusEnum.Empty);
  };

  const subscribeBookHandler = async () => {
    if (!isLoggedIn) {
      dispatch(subscribeBookModalActions.setIsOpenLoginModalWithSubscribeBook(true));
    } else {
      dispatch(subscribeBookAndOpenModal({ book }));
    }
    setIsVisibleList(false);
  };

  const bookText = () => {
    if (book?.myBook?.readStatus === MyBookStatusEnum.readNow) {
      return (
        <>
          <SCIconWrapper>
            {loading
              ? <SCLoaderIcon />
              : <SCReadersIcon />}
          </SCIconWrapper>
          {isShowText ? <SCText>{MyBookStatusTextEnum[book?.myBook?.readStatus]}</SCText> : null}
        </>
      );
    }
    if (book?.myBook?.readStatus === MyBookStatusEnum.readLater) {
      return (
        <>
          <SCIconWrapper>
            {loading
              ? <SCLoaderIcon />
              : <SCBookmarkIcon />}
          </SCIconWrapper>
          {isShowText ? <SCText>{MyBookStatusTextEnum[book?.myBook?.readStatus]}</SCText> : null}
        </>
      );
    }
    if (book?.myBook?.readStatus === MyBookStatusEnum.read) {
      return (
        <>
          <SCIconWrapper>
            {loading
              ? <SCLoaderIcon />
              : <SCBooksIcon />}
          </SCIconWrapper>
          {isShowText ? <SCText>{MyBookStatusTextEnum.read}</SCText> : null}
        </>
      );
    }
    return (
      <>
        <SCIconWrapper>
          {loading
            ? <SCLoaderIcon />
            : <SCPlusIcon />}
        </SCIconWrapper>
        {isShowText ? <SCText>В библиотеку</SCText> : null}
      </>
    );
  };

  if (!isClient) return null;

  return (
    <SCStatusBook
      style={style}
      data-theme={theme}
      onClick={onClick}
    >
      {isLoggedIn ? (
        <Dropdown
          placement={placement}
          trigger={['click']}
          open={isVisibleList}
          onOpenChange={setIsVisibleList}
          forceRender
          overlay={(
            <SCStatusMenu ref={refRegister}>
              <ReadBookStatusList
                setStatus={setStatus}
                status={book?.myBook?.readStatus}
                onDelete={onDeleteStatusHandler}
              />
            </SCStatusMenu>
          )}
        >
          <SCButtonWrap
            type="gray"
            size={buttonSize}
            {...props}
          >
            {bookText()}
          </SCButtonWrap>
        </Dropdown>
      )
        : (
          <SCButtonWrap
            type="gray"
            size={buttonSize}
            onClick={subscribeBookHandler}
            {...props}
          >
            <SCIconWrapper>
              {loading
                ? <SCLoaderIcon />
                : <SCPlusIcon />}
            </SCIconWrapper>
            {isShowText ? <SCText>В библиотеку</SCText> : null}
          </SCButtonWrap>
        )}
    </SCStatusBook>
  );
};

const SCLoaderIcon = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
`;

const SCText = styled.span`
  ${TextBoldStyle};
`;

const SCPlusIcon = styled(AddIcon)`
  font-size: 20px;
  color: var(--black-color);
`;

const SCBooksIcon = styled(BooksIcon)`
  font-size: 18px;
  color: var(--black-color);
`;

const SCButtonWrap = styled(Button)`
  width: 100%;
  border: 1px solid var(--gray3-color) !important;
  
  > *:not(:last-child) {
    margin-right: 6px;
  }
`;

const SCReadersIcon = styled(ReadersIcon)`
  font-size: 20px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCBookmarkIcon = styled(BookmarkIcon)`
  font-size: 20px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCStatusBook = styled.div`
  position: relative;
  flex-shrink: 0;

  &[data-theme=${BookReaderThemeEnum.Dark}] {
    ${SCText}, ${SCPlusIcon}, ${SCBookmarkIcon}, ${SCReadersIcon} {
      color: var(--white-color);
    }
    
    ${SCButtonWrap} {
      &:hover {
        border: 1px solid var(--white-color);
      }
    }
  }

  &[data-theme=${BookReaderThemeEnum.Sandy}] {
    ${SCText}, ${SCPlusIcon}, ${SCBookmarkIcon}, ${SCReadersIcon} {
      color: var(--black-color);
    }

    ${SCButtonWrap} {
      border: 1px solid var(--black-color);
    }
  }
`;

const SCStatusMenu = styled.div`
  border: 1px solid var(--gray3-color);
  background-color: var(--bg-color);
  max-width: 232px;
  width: 100%;
  box-shadow: 0 3px 5px rgba(53, 63, 72, 0.25);
  border-radius: 2px;
  padding: 16px 16px 16px 19px;
`;

const SCIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
