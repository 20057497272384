import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { Book } from '~/api/book/bookApiTypes';
import { changeBookStatus } from '~/atomic/page/mybooks/myBooks.data';
import { getGraphqlBook } from '~/feature/book/book.data';
import {
  subscribeBookModalActions,
  subscribeBookModalSliceName,
} from '~/feature/book/SubscribeBookModal/subscribeBookModal.slice';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { RootState } from '~/store';

export const subscribeBookAndOpenModal = createAsyncThunk<
void, { book: Partial<Book | GraphqlBook> }, { state: RootState }
>(`${subscribeBookModalSliceName}/subscribeBookAndOpenModal`, async (data, thunkAPI) => {
  const { book: { id, myBook } } = data;

  thunkAPI.dispatch(subscribeBookModalActions.setIsSubscribeBookLoading(true));
  const changeBookStatusResult = await thunkAPI.dispatch(changeBookStatus({
    bookId: id,
    currentStatus: myBook?.readStatus,
    status: MyBookStatusEnum.readNow,
  }));

  await thunkAPI.dispatch(getGraphqlBook({ id }));
  const parsedLocation = qs.parseUrl(String(window.location));

  if (parsedLocation.query?.[subscribeBookModalSliceName]) {
    parsedLocation.query[subscribeBookModalSliceName] = undefined;

    const url = new URL(qs.stringifyUrl(parsedLocation));

    const redirectUrl = url.pathname + url.search + url.hash;

    Router.replace(Router.pathname, redirectUrl, { shallow: true });
  }
  thunkAPI.dispatch(subscribeBookModalActions.setIsSubscribeBookLoading(false));

  if (changeBookStatus.fulfilled.match(changeBookStatusResult)) {
    ym('reachGoal', 'subscribe-new-chapters-author');

    thunkAPI.dispatch(subscribeBookModalActions.setIsOpenModal(true));
  }
});
